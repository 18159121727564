/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

/**
 * Register service worker for PWA functionality
 */
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This website has been updated with new features. Would you like to reload to see the latest version?'
  );

  if (answer === true) {
    window.location.reload();
  }
};

// Register service worker manually if needed
export const onInitialClientRender = () => {
  if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
    // Ensure SW registration on first load
    window.addEventListener('load', () => {
      // Check if service worker is registered
      navigator.serviceWorker.getRegistration('/').then(registration => {
        // If no registration exists for the scope, try registering again
        if (!registration) {
          console.log('No service worker found, attempting registration');
          navigator.serviceWorker.register('/sw.js', { scope: '/' })
            .then(reg => {
              console.log('Service worker registered successfully', reg);
            })
            .catch(error => {
              console.error('Service worker registration failed:', error);
            });
        } else {
          console.log('Service worker already registered');
        }
      });
    });
  }
};

/**
 * Preload critical assets for faster rendering
 */
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== 'undefined') {
    // Preload critical images on homepage - using relative paths without content hash
    if (location.pathname === '/' || location.pathname === '') {
      // Check if astronaut image exists in the page already
      setTimeout(() => {
        const heroImage = document.querySelector('img[src*="astro"]') || document.querySelector('img[src*="astronaut"]');
        
        if (heroImage && heroImage.getAttribute('src')) {
          // If we found the image, preload with the correct path
          const link = document.createElement('link');
          link.rel = 'preload';
          link.as = 'image';
          link.href = heroImage.getAttribute('src');
          link.fetchPriority = 'high';
          document.head.appendChild(link);
        }
      }, 0);
    }
    
    // Look for existing CSS files to preload rather than hard-coded paths
    setTimeout(() => {
      const stylesheets = Array.from(document.querySelectorAll('link[rel="stylesheet"]'))
        .filter(link => link.href.includes('.css'))
        .map(link => link.href);
        
      // Preload first 2 CSS files (likely critical ones)
      stylesheets.slice(0, 2).forEach(cssHref => {
        if (!document.querySelector(`link[rel="preload"][href="${cssHref}"]`)) {
          const link = document.createElement('link');
          link.rel = 'preload';
          link.as = 'style';
          link.href = cssHref;
          link.fetchPriority = 'high';
          document.head.appendChild(link);
        }
      });
    }, 10);
  }
};

/**
 * Add resource hints and improve Core Web Vitals
 */
export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    // Function to defer third-party scripts
    const deferThirdPartyScripts = () => {
      // Find all third-party scripts with defer attribute
      const thirdPartyScripts = document.querySelectorAll(
        'script[src*="googletagmanager.com"], script[src*="google-analytics.com"], script[src*="clarity.ms"]'
      );
      
      // Add loading delay to non-critical third-party scripts
      thirdPartyScripts.forEach(script => {
        script.setAttribute('defer', '');
        script.setAttribute('async', '');
      });
    };

    // Prioritize only critical resources 
    if (document.head && window.requestIdleCallback) {
      // Immediate changes for critical resources - prioritize the first two CSS files
      const allStyles = Array.from(document.querySelectorAll('link[rel="stylesheet"]'));
      allStyles.slice(0, 2).forEach(link => {
        link.setAttribute('fetchpriority', 'high');
      });
      
      // Defer non-critical operations
      window.requestIdleCallback(() => {
        // Deprioritize non-critical stylesheets (all except the first two)
        const allStyles = Array.from(document.querySelectorAll('link[rel="stylesheet"]'));
        allStyles.slice(2).forEach(link => {
          link.setAttribute('fetchpriority', 'low');
        });

        // Add preconnect for external domains
        const preconnectDomains = [
          'https://www.googletagmanager.com',
          'https://www.google-analytics.com',
          'https://www.clarity.ms'
        ];
        
        preconnectDomains.forEach(domain => {
          const link = document.createElement('link');
          link.rel = 'preconnect';
          link.href = domain;
          link.crossOrigin = 'anonymous';
          document.head.appendChild(link);
        });
        
        // Defer third-party scripts
        deferThirdPartyScripts();
      }, { timeout: 1000 });
    }
    
    // Apply native lazy loading to all images below the fold
    if ('loading' in HTMLImageElement.prototype) {
      const lazyImages = document.querySelectorAll('img:not([loading])');
      lazyImages.forEach(img => {
        if (!img.classList.contains('gatsby-image-wrapper img') && !img.closest('header')) {
          img.setAttribute('loading', 'lazy');
        }
      });
    }
  }
};

/**
 * Enhance page performance on route changes
 */
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (typeof window !== 'undefined') {
    if (prevLocation !== null) {
      // Scroll to top on page changes (debounced)
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
    
    // Wait for critical content to be available before loading non-critical items
    setTimeout(() => {
      // Prioritize visible images on route change using IntersectionObserver
      if ('IntersectionObserver' in window) {
        // Use a more efficient threshold for image loading
        const imgObserver = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const img = entry.target;
              
              // Handle data-src lazy loading
              if (img.dataset.src) {
                img.src = img.dataset.src;
                img.removeAttribute('data-src');
              }
              
              // Handle srcset lazy loading
              if (img.dataset.srcset) {
                img.srcset = img.dataset.srcset;
                img.removeAttribute('data-srcset');
              }
              
              // Add decoding attribute if missing
              if (!img.getAttribute('decoding')) {
                img.setAttribute('decoding', 'async');
              }
              
              imgObserver.unobserve(img);
            }
          });
        }, {
          rootMargin: '200px 0px', // Load images slightly before they enter the viewport
          threshold: 0.01 // Trigger with minimal visibility
        });
        
        // Observe all images that should be lazy loaded
        document.querySelectorAll('img[data-src], img:not([loading])').forEach(img => {
          // Skip images in the header (likely critical)
          if (!img.closest('header')) {
            imgObserver.observe(img);
          }
        });
      }
      
      // Purge unnecessary DOM elements for better performance
      const commentsAndUnusedNodes = document.createNodeIterator(
        document.documentElement,
        NodeFilter.SHOW_COMMENT,
        null
      );
      let currentNode;
      while (currentNode = commentsAndUnusedNodes.nextNode()) {
        currentNode.parentNode.removeChild(currentNode);
      }
      
      // Delay third-party script execution further down the page
      if (document.querySelector('footer')) {
        const thirdPartyScripts = document.querySelectorAll(
          'script[src*="googletagmanager.com"], script[src*="google-analytics.com"], script[src*="clarity.ms"]'
        );
        
        if (thirdPartyScripts.length > 0) {
          const footer = document.querySelector('footer');
          thirdPartyScripts.forEach(script => {
            // Move script to the end of the body to delay execution
            document.body.appendChild(script);
          });
        }
      }
    }, 100); // Small delay to ensure DOM is ready
  }
};

